import React from "react";
import PhotoEva from "../assets/eva.jpeg";
import PhotoJokin from "../assets/jokin.jpeg";

const Photos: React.FC = (props) => {
  return (
    <div className="section" id="photos">
      <div className="photos-cont">
        <img className="eva" src={PhotoEva} alt="eva" />
        <p className="eva-text">
          Única en su especie, Eva es el alma de la fiesta allá donde va.
          Explosiva, extrovertida y ocurrente, siempre está lista para animar
          cualquier juerga y hacer que todos se diviertan al máximo. Su pasión
          por viajar la ha llevado a descubrir el mundo y ahora se embarca en la
          aventura más emocionante de todas. ¡Prepárense para una boda llena de
          diversión, risas y momentos inolvidables!
        </p>
      </div>
      <div className="photos-cont">
        <img className="jokin" src={PhotoJokin} alt="jokin" />
        <p className="jokin-text">
          Jokin es el amigo más famoso que tenemos, y no es para menos. Dejó
          atrás el verdor de Aizarnazabal y a los pulpos tranquilos en Zumaia
          para embarcarse en aventuras épicas. Desde cruzar el charco y
          aterrizar en Chile para aprender español en pleno Erasmus, hasta
          mudarse a Valencia con el amor de su vida y una nueva ciudad para
          conquistar.
          <br />
          Jokin es una mezcla perfecta de humildad y alboroto. Es sencillo, pero
          siempre logra hacerse notar. Un showman de los pies a la cabeza, y
          cuando está cerca, no hay aburrimiento posible. En MasterChef se la
          jugó con sus platos, pero el verdadero secreto de Jokin es su
          autenticidad. Es genuino y transparente, siempre fiel a sí mismo y a
          sus amigos.
        </p>
      </div>
    </div>
  );
};

export default Photos;
