import React from "react";

interface IAddress {}

const Address: React.FC<IAddress> = (props) => {
  return (
    <div className="section" id="page-address">
      <div className="section-column">
        <h2>DIRECCIÓN</h2>
        <div className="google-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3042.6495771655714!2d0.21021299999999998!3d40.305738999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDDCsDE4JzIwLjciTiAwwrAxMiczNi44IkU!5e0!3m2!1ses!2ses!4v1713361103133!5m2!1ses!2ses"
            title="maps"
            width="500"
            height="250"
            style={{ border: 0 }}
            // allowfullscreen={false}
            loading="lazy"
            // referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="google-map__text">
          <p>Masía Sierra de Irta</p>
          <p>Camí de la Bassa Llona s/n, 12570 Alcala de Xivert, Castellón</p>
        </div>
      </div>
      {/* <div className="section-column">
        <h2>ALOJAMIENTO</h2>
               <p>
          Estamos mirando opciones. Elegid la vuestra{" "}
          <strong>en el formulario:</strong>
        </p>
        <ul>
          <li>
            Dormir en la masía aunque haya que compartir habitación con otras
            personas. (Precio: 37€/persona/noche ).
          </li>
          <li>
            ⁠Contad conmigo a la hora de bloquear habitación en algún hotel
            (Alcalá de Xivert o Alcocebre). Pronto pondremos las opciones, antes
            necesitamos saber aprox cuanta gente quiere esto para conseguir los
            mejores descuentos.
          </li>
          <li>Lo reservaré por mi cuenta</li>
        </ul>
        <h2 className="transport">TRANSPORTE</h2>

        <p>Se puede llegar en coche o tren desde Valencia a Alcalá de Xivert</p>
        <p>
          <strong>País Vasco:</strong> Se está planteando poner un bus desde
          Zumaia. Horario: salida viernes sobre las 11 y vuelta el domingo sobre
          las 16-17h. (Precio a definir, +- 100-150 euros/pax). Si estás
          interesado, <strong>respóndelo en el formulario.</strong>
        </p>
      </div> */}
    </div>
  );
};

export default Address;
