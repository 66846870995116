import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { firebaseAuth } from "../utils/firebase";
import LogoMorado from "../assets/LogoMorado.png";

interface ICloseCallback {
  closeCallback: () => void;
}

const Login: React.FC<ICloseCallback> = ({ closeCallback }) => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onLogin = async () => {
    setIsLoading(true);
    await signInWithEmailAndPassword(firebaseAuth, email, password);
    setIsLoading(false);
    closeCallback();
  };

  return (
    <div className="section" id="login">
      <div
        className="login__backdrop"
        onClick={(e) => {
          e.stopPropagation();
          closeCallback();
        }}
      ></div>
      <div className="login-box input-icons">
        <img className="logomorado" src={LogoMorado} />
        <label htmlFor="uname">
          <b></b>
        </label>
        <input
          className="item-input-text login"
          type="text"
          placeholder="Email"
          name="uname"
          required
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <label htmlFor="psw">
          <b></b>
        </label>
        <input
          className="item-input-text login"
          type="password"
          placeholder="Contraseña"
          name="psw"
          required
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <button className="btn-access" onClick={onLogin}>
          {!isLoading ? "Entrar" : "Entrando..."}
        </button>
      </div>{" "}
    </div>
  );
};

export default Login;
