import React from "react";
import LogoRosa from "../assets/LogoRosa.png";

interface IOpenLogin {
  handleOpenLogin: () => void;
}

const Header: React.FC<IOpenLogin> = ({ handleOpenLogin }) => {
  return (
    <header className="main-header">
      <img className="letters" src={LogoRosa} alt="E(L)J" />
      <nav>
        <a href="#page-program">Programa</a>
        <a href="#page-address">Dirección</a>
        {/* <a href="#page-address">Alojamiento y Transporte</a> */}
        <a href="#page-rsvp">RSVP</a>
        <a href="#page-gifts">Regalo</a>
        {/* <a href="#page-gallery">Galería</a> */}
      </nav>
    </header>
  );
};

export default Header;
