// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAyBvmfl16R_DHb2P21_h6t64d3Dylb1z4",
  authDomain: "eva-y-jokin.firebaseapp.com",
  projectId: "eva-y-jokin",
  storageBucket: "eva-y-jokin.appspot.com",
  messagingSenderId: "789089449348",
  appId: "1:789089449348:web:82730e6e898290426fd9d1",
  measurementId: "G-XZXXZWK9Q0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(app);
export const fireStore = getFirestore(app);
