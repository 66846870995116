import React from "react";
import SaveTheDate from "../assets/save.png";

const STD: React.FC = () => {
  return (
    <div className="section" id="save-date">
      <img className="save-image" src={SaveTheDate} alt="save the date" />
      <p className="save-text">
        Como bien nos conoces, sabes que no vamos a celebrar una boda
        tradicional. Así que resérvate todo el finde porque vamos a despedir el
        verano como nosotros sabemos, ¡Por todo lo alto!
      </p>
    </div>
  );
};

export default STD;
