import React, { useMemo } from "react";
import { Control, useWatch } from "react-hook-form";
import { IRsvp } from "../utils/rsvpInterface";

interface IRsvpErrors {
  control: Control<IRsvp, any>;
}

const RsvpErrors: React.FC<IRsvpErrors> = ({ control }) => {
  const hasGuest = useWatch({ control, name: "hasGuest" });
  const name = useWatch({ control, name: "name" });
  const events = useWatch({ control, name: "events" });
  const guest_name = useWatch({ control, name: "guest_name" });
  const guest_events = useWatch({ control, name: "guest_events" });
  const guest_diet = useWatch({ control, name: "guest_diet" });

  const errors = useMemo(() => {
    const list: string[] = [];
    if (!name) {
      list.push("Falta tu nombre");
    }
    if (!events.length) {
      list.push("Selecciona al menos un evento");
    }
    if (hasGuest) {
      if (!guest_name) {
        list.push("Falta el nombre del acompañante");
      }
      if (!guest_events || !guest_events.length) {
        list.push("Selecciona al menos un evento del acompañante");
      }
      if (!guest_diet) {
        list.push("Falta la dieta del acompañante");
      }
    }
    return list;
  }, [name, events, guest_name, guest_events, guest_diet, hasGuest]);

  return (
    <ul className="form__error-list" id="form-errors">
      {errors.map((line, index) => (
        <li className="form__error-list__item" key={index}>
          {line}
        </li>
      ))}
    </ul>
  );
};

export default RsvpErrors;
