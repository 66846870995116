import React from "react";
import ManoArriba from "../assets/manoarriba.png";

const Program: React.FC = () => {
  return (
    <div className="section" id="page-program">
      <img className="img-manoarriba" src={ManoArriba} />
      <div className="section-column">
        <h2>PRE-BODA</h2>
        <p>
          <strong>Hora:</strong> 18/18:30h
        </p>
        <p>
          <strong>Qué haremos:</strong> : La idea es pasarlo GENIAL y conocer al
          resto de invitados. Haremos algún juego divertido, cocinaremos,
          cenaremos…
        </p>
        <p>
          <strong>Dress code:</strong> Ropa cómoda y fresquita.
        </p>
      </div>
      <div className="section-column">
        <h2>BODA</h2>
        <p>
          <strong>Hora:</strong> 12h
        </p>
        <p>
          <strong>Qué haremos:</strong> Va a ser un FIESTÓN, así que ven con
          muchas ganas de bailar y reír, unos kleenex y el hígado reforzado.
        </p>
        <p>
          <strong>Dress code:</strong> Cocktail.{" "}
          <strong>Lo importante es pasarlo bien.</strong>
          <p>
            Chicos: corbatas y americanas no son obligatorias, pero tampoco
            están prohibidas (los chándales SÍ lo están). Ejemplo: Pantalones
            chinos largos, camisa y americana.{" "}
          </p>
          <p>
            Chicas: vais a estar preciosas con lo que os pongáils. Lo importante
            es que os sintáis guapas y cómodas.
          </p>
        </p>
      </div>
      <div className="section-column">
        <h2>POST-BODA</h2>
        <p>
          <strong>Hora:</strong> Desde que os despertéis, pero antes de las
          14/14:30 que será la comida.
        </p>
        <p>
          <strong>Qué haremos:</strong> Piscina y paella (y cervecitas para los
          más valientes). Para pasar bien la resaca y comentar el día anterior.
        </p>
        <p>
          <strong>Dress code:</strong> Gafas de sol, crema de sol,
          bikini/bañador (aquí podéis poneros todo lo originales que queráis),
          chanclas, y la dignidad que nos quede.
        </p>
      </div>
    </div>
  );
};

export default Program;
