import React, { useState, useEffect } from "react";
import { getDocs, collection } from "firebase/firestore";
import { fireStore } from "./firebase";
import * as XLSX from "xlsx";
import { IRsvp } from "./rsvpInterface";

const useExcel = () => {
  const fitToColumn = (arrayOfArray: string[][]) => {
    // get maximum character of each column
    return arrayOfArray[0].map((a, i) => ({
      wch: Math.max(
        ...arrayOfArray.map((a2) => (a2[i] ? a2[i].toString().length : 0))
      ),
    }));
  };
  const handleClick = async () => {
    const collectionRef = collection(fireStore, "rsvp");

    try {
      const snapshot = await getDocs(collectionRef);
      const documents = snapshot.docs.map((doc) => doc.data()) as IRsvp[];
      const wb = XLSX.utils.book_new();
      const aoa = [
        [
          "Nombre",
          "Dieta",
          "Eventos",
          "Alergias",
          "Alojamiento",
          "Bus",
          "Acompañante",
          "Nombre Acompañante",
          "Dieta Acompañante",
          "Eventos Acompañante",
          "Alergias Acompañante",
        ],
      ].concat(
        documents.map(
          ({
            name,
            diet,
            events,
            alergies,
            accommodation,
            bus,
            hasGuest,
            guest_name,
            guest_diet,
            guest_events,
            guest_alergies,
          }) => [
            name,
            diet,
            events.toString(),
            alergies,
            accommodation,
            bus ? "Sí" : "No",
            hasGuest ? "Sí" : "No",
            guest_name ?? "",
            guest_diet ? (guest_diet as unknown as string) : "",
            guest_events?.toString() ?? "",
            guest_alergies ?? "",
          ]
        )
      );
      let ws = XLSX.utils.aoa_to_sheet(aoa);
      ws["!cols"] = fitToColumn(aoa);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, "Resumen invitaciones.xlsx");
    } catch (error) {
      console.error("Error fetching documents: ", error);
    }
  };
  return handleClick;
};

export default useExcel;
