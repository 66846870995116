import React from "react";
import Circular from "../assets/circular.png";

const Gifts: React.FC = () => {
  return (
    <div className="section" id="page-gifts">
      <img className="img-circular" src={Circular} />
      <div className="text">
        <p>
          {" "}
          Sabemos que una boda de estas características es un esfuerzo para
          todos vosotros, y nuestro mayor regalo es vuestra presencia, pero si
          queréis ayudarnos, ¡Cualquier aportación va a ser más que agradecida!{" "}
        </p>
        <div className="bank">
          <p>
            <strong>CUENTA CORRIENTE:</strong>
          </p>
          <p>
            <strong>ES98 0081 2804 6500 0891 7701</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Gifts;
