import React from "react";
import { firebaseAuth } from "../utils/firebase";
import { signOut } from "firebase/auth";
import useExcel from "../utils/useExcel";

interface IOpenLogin {
  handleOpenLogin: () => void;
}

const Hero: React.FC<IOpenLogin> = ({ handleOpenLogin }) => {
  const { currentUser } = firebaseAuth;

  const logout = async () => {
    await signOut(firebaseAuth);
    window.location.reload();
  };

  const onDownloadClick = useExcel();

  return (
    <div className="section" id="page-photo">
      <p className="section-text">¡NOS CASAMOS!</p>
      <h1 className="couple-names">Eva y Jokin</h1>
      {/* <!-- <img className="couple-names" src="assets/propuesta_2__1_-removebg-preview.png" /> --> */}
      <p className="section-text date">
        <span>30 AGOSTO</span> <span>-</span> <span>1 SEPTIEMBRE 2024</span>
      </p>
      {!currentUser && (
        <button
          className="btn"
          onClick={currentUser ? logout : handleOpenLogin}
        >
          ACCEDE A LA INVITACIÓN
        </button>
      )}
      {currentUser?.email === process.env.REACT_APP_MAIL_ADMIN && (
        <button className="btn" onClick={onDownloadClick}>
          Descargar reporte
        </button>
      )}
    </div>
  );
};

export default Hero;
