import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import Hero from "./pages/Hero";
import Program from "./pages/Program";
import Address from "./pages/Address";
import RSVP from "./pages/RSVP";
import Gifts from "./pages/Gifts";
import Gallery from "./pages/Gallery";
import Login from "./components/Login";
import { firebaseAuth } from "./utils/firebase";
import { initEmailService } from "./utils/email";
import STD from "./pages/STD";
import Photos from "./pages/Photos";

function App() {
  const [isLoginOpen, setIsLoginOpen] = useState(false);

  const { currentUser } = firebaseAuth;

  const onOpenLogin = () => {
    setIsLoginOpen(true);
  };

  const onCloseLogin = () => {
    setIsLoginOpen(false);
  };

  useEffect(() => {
    initEmailService();
  }, []);

  return (
    <div className="App">
      {!!currentUser && <Header handleOpenLogin={onOpenLogin} />}
      <main>
        <Hero handleOpenLogin={onOpenLogin} />
        {isLoginOpen && <Login closeCallback={onCloseLogin} />}
        {!!currentUser && (
          <>
            <STD />
            <Photos />
            <Program />
            <Address />
            <RSVP />
            <Gifts />
            {/* <Gallery /> */}
          </>
        )}
      </main>
    </div>
  );
}

//m60JQb5kSeMymPf

export default App;
