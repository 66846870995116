import React, { useEffect, useMemo, useState } from "react";
import Corazon from "../assets/corazon.png";
import { useForm, SubmitHandler, useWatch } from "react-hook-form";
import { IRsvp } from "../utils/rsvpInterface";
import { handleSubmitForm } from "../utils/email";
import RsvpErrors from "../components/RsvpError";

const defaultValues: IRsvp = {
  name: "",
  events: [],
  diet: "Normal",
  accommodation: "Por mi cuenta",
  alergies: "",
  bus: false,
  hasGuest: false,
};

const RSVP: React.FC = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitted, isSubmitting, isDirty },
    reset,
    setValue,
  } = useForm<IRsvp>({ defaultValues });

  const [areErrorsVisible, setAreErrorsVisible] = useState(false);

  const hasGuest = useWatch({ control, name: "hasGuest" });
  const name = useWatch({ control, name: "name" });
  const events = useWatch({ control, name: "events" });
  const guest_name = useWatch({ control, name: "guest_name" });
  const guest_events = useWatch({ control, name: "guest_events" });
  const guest_diet = useWatch({ control, name: "guest_diet" });

  const isFormCompleted = useMemo(() => {
    const isMainCompleted = name !== "" && events.length > 0;
    if (hasGuest) {
      const isGuestCompleted =
        guest_name !== "" && guest_diet !== undefined && !!guest_events?.length;
      return isMainCompleted && isGuestCompleted;
    } else {
      return isMainCompleted;
    }
  }, [name, events, guest_name, guest_events, guest_diet, hasGuest]);

  useEffect(() => {
    if (!hasGuest) {
      setValue("guest_alergies", undefined);
      setValue("guest_name", undefined);
      setValue("guest_events", undefined);
      setValue("guest_diet", undefined);
    }
  }, [hasGuest, setValue]);

  const showErrors = () => {
    setAreErrorsVisible(true);
    document.getElementById("form-errors")?.scrollIntoView({ block: "end" });
  };

  if (isSubmitted) {
    return (
      <div className="section" id="page-rsvp">
        <img className="img-corazon" src={Corazon} />
        <h2 className="rsvp-header">Confirmación enviada correctamente</h2>
        <button className="form__btn" onClick={() => reset(defaultValues)}>
          Reiniciar formulario
        </button>
      </div>
    );
  }

  return (
    <div className="section" id="page-rsvp">
      <img className="img-corazon" src={Corazon} />
      <h2 className="rsvp-header">Confirma tu asistencia</h2>
      <form className="form-component">
        <div className={`form-container ${hasGuest ? "acompanante" : ""}`}>
          <div className="form-item">
            <label className="item-label" htmlFor="nombre">
              Tu nombre
            </label>
            <input
              className="item-input-text"
              type="text"
              id="nombre"
              {...register("name")}
            />
          </div>

          <p className="label">¿A qué eventos vas a asistir?</p>
          <div className="form-checkbox">
            <input
              type="checkbox"
              id="pre-boda"
              value={"Pre-boda"}
              {...register("events")}
            />
            <label className="item-label" htmlFor="pre-boda">
              Pre-boda
            </label>
          </div>
          <div className="form-checkbox">
            <input
              type="checkbox"
              id="boda"
              value={"Boda"}
              {...register("events")}
            />
            <label className="item-label" htmlFor="boda">
              Boda
            </label>
          </div>
          <div className="form-checkbox">
            <input
              type="checkbox"
              id="post-boda"
              value={"Post-boda"}
              {...register("events")}
            />
            <label className="item-label" htmlFor="post-boda">
              Post-boda
            </label>
          </div>

          <p className="label">Dieta</p>
          <div className="form-checkbox">
            <input
              type="radio"
              id="normal"
              value={"Normal"}
              {...register("diet")}
            />
            <label className="item-label" htmlFor="normal">
              Normal
            </label>
          </div>
          <div className="form-checkbox">
            <input
              type="radio"
              id="vegetariana"
              value={"Vegetariana"}
              {...register("diet")}
            />
            <label className="item-label" htmlFor="vegetariana">
              Vegetariana
            </label>
          </div>
          <div className="form-checkbox">
            <input
              type="radio"
              id="vegana"
              value={"Vegana"}
              {...register("diet")}
            />
            <label className="item-label" htmlFor="vegana">
              Vegana
            </label>
          </div>
          <div className="form-checkbox">
            <input
              type="radio"
              id="celiaca"
              value={"Celíaca"}
              {...register("diet")}
            />
            <label className="item-label" htmlFor="celiaca">
              Celíaca
            </label>
          </div>
          <div className="form-item">
            <label className="item-label, label" htmlFor="alergias">
              Alergias o intolerancias
            </label>
            <textarea
              className="item-input-text"
              id="alergias"
              {...register("alergies")}
            ></textarea>
          </div>

          <p className="label">Alojamiento</p>
          <div className="form-checkbox">
            <input
              type="radio"
              id="masia"
              value={"Masía"}
              {...register("accommodation")}
              disabled
            />
            <label className="item-label" htmlFor="masia">
              Masía (completo)
            </label>
          </div>
          <div className="form-checkbox">
            <input
              type="radio"
              id="hotel"
              value={"Hotel"}
              {...register("accommodation")}
              disabled
            />
            <label className="item-label" htmlFor="hotel">
              Hotel (completo)
            </label>
          </div>
          <div className="form-checkbox">
            <input
              type="radio"
              id="hotel"
              value={"Camping"}
              {...register("accommodation")}
            />
            <label className="item-label" htmlFor="hotel">
              Camping Tropicana
            </label>
          </div>
          <div className="form-checkbox">
            <input
              type="radio"
              id="cuenta"
              value={"Por mi cuenta"}
              {...register("accommodation")}
            />
            <label className="item-label" htmlFor="cuenta">
              Por mi cuenta
            </label>
          </div>
          {/* <p className="label">Autobús (País Vasco)</p>
          <div className="form__row">
            <div className="form-checkbox">
              <input
                type="radio"
                id="guest-si"
                value={"true"}
                {...register("bus")}
              />
              <label className="item-label" htmlFor="guest-si">
                Sí
              </label>
            </div>
            <div className="form-checkbox">
              <input
                type="radio"
                id="guest-no"
                value={undefined}
                {...register("bus")}
              />
              <label className="item-label" htmlFor="guest-no">
                No
              </label>
            </div>
          </div> */}

          <p className="label">Acompañante</p>
          <div className="form__row">
            <div className="form-checkbox">
              <input
                type="radio"
                id="guest-si"
                value={"true"}
                {...register("hasGuest")}
              />
              <label className="item-label" htmlFor="guest-si">
                Sí
              </label>
            </div>
            <div className="form-checkbox">
              <input
                type="radio"
                id="guest-no"
                value={undefined}
                {...register("hasGuest")}
              />
              <label className="item-label" htmlFor="guest-no">
                No
              </label>
            </div>
          </div>
        </div>
        {hasGuest && (
          <div className={`form-container ${hasGuest ? "acompanante" : ""}`}>
            <div className="form-item">
              <label className="item-label" htmlFor="nombre">
                Acompañante
              </label>
              <input
                className="item-input-text"
                type="text"
                id="nombre"
                {...register("guest_name")}
              />
            </div>

            <p className="label">¿A qué eventos va a asistir?</p>
            <div className="form-checkbox">
              <input
                type="checkbox"
                id="pre-boda"
                value={"Pre-boda"}
                {...register("guest_events")}
              />
              <label className="item-label" htmlFor="pre-boda">
                Pre-boda
              </label>
            </div>
            <div className="form-checkbox">
              <input
                type="checkbox"
                id="boda"
                value={"Boda"}
                {...register("guest_events")}
              />
              <label className="item-label" htmlFor="boda">
                Boda
              </label>
            </div>
            <div className="form-checkbox">
              <input
                type="checkbox"
                id="post-boda"
                value={"Post-boda"}
                {...register("guest_events")}
              />
              <label className="item-label" htmlFor="post-boda">
                Post-boda
              </label>
            </div>

            <p className="label">Dieta</p>
            <div className="form-checkbox">
              <input
                type="radio"
                id="normal"
                value={"Normal"}
                {...register("guest_diet")}
              />
              <label className="item-label" htmlFor="normal">
                Normal
              </label>
            </div>
            <div className="form-checkbox">
              <input
                type="radio"
                id="vegetariana"
                value={"Vegetariana"}
                {...register("guest_diet")}
              />
              <label className="item-label" htmlFor="vegetariana">
                Vegetariana
              </label>
            </div>
            <div className="form-checkbox">
              <input
                type="radio"
                id="vegana"
                value={"Vegana"}
                {...register("guest_diet")}
              />
              <label className="item-label" htmlFor="vegana">
                Vegana
              </label>
            </div>
            <div className="form-checkbox">
              <input
                type="radio"
                id="celiaca"
                value={"Celíaca"}
                {...register("guest_diet")}
              />
              <label className="item-label" htmlFor="celiaca">
                Celíaca
              </label>
            </div>
            <div className="form-item">
              <label className="item-label, label" htmlFor="alergias">
                Alergias o intolerancias
              </label>
              <textarea
                className="item-input-text"
                id="alergias"
                {...register("guest_alergies")}
              ></textarea>
            </div>
          </div>
        )}
      </form>
      <button
        className="form__btn"
        onClick={isFormCompleted ? handleSubmit(handleSubmitForm) : showErrors}
        disabled={isSubmitting}
      >
        Confirmar
      </button>
      {areErrorsVisible && <RsvpErrors control={control} />}
    </div>
  );
};

export default RSVP;
