import emailjs from "@emailjs/browser";
import { SubmitHandler } from "react-hook-form";
import { IRsvp } from "./rsvpInterface";
import { doc, setDoc } from "firebase/firestore";
import { fireStore } from "./firebase";

export const initEmailService = () => {
  emailjs.init(process.env.REACT_APP_MAIL_PUBLIC_KEY as string);
};

export const handleSubmitForm: SubmitHandler<IRsvp> = async (form) => {
  const { hasGuest, bus } = form;
  const templateId = (
    hasGuest
      ? process.env.REACT_APP_MAIL_GUEST_TEMPLATE_ID
      : process.env.REACT_APP_MAIL_SINGLE_TEMPLATE_ID
  ) as string;
  const serviceId = process.env.REACT_APP_MAIL_SERVICE_ID as string;
  await emailjs.send(serviceId, templateId, {
    ...form,
    hasGuest: hasGuest ? "Sí" : "No",
    bus: bus ? "Sí" : "No",
  });
  const rsvpDoc = doc(fireStore, "rsvp", new Date().getTime().toString());
  await setDoc(rsvpDoc, form);
};
